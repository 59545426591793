import { __decorate, __metadata } from "tslib";
import { injectable } from 'core/decorators';
var AppInsights = /** @class */ (function () {
    function AppInsights() {
        this.appInsights = window.appInsights;
    }
    AppInsights.prototype.trackPageView = function (name, url, properties, measurements, duration) {
        return this.appInsights && this.appInsights.trackPageView(name, url, properties, measurements, duration);
    };
    AppInsights.prototype.startTrackPage = function (name) {
        return this.appInsights && this.appInsights.startTrackPage(name);
    };
    AppInsights.prototype.stopTrackPage = function (name, url) {
        return this.appInsights && this.appInsights.stopTrackPage(name, url);
    };
    AppInsights.prototype.trackEvent = function (name, properties) {
        return this.appInsights && this.appInsights.trackEvent(name, properties);
    };
    AppInsights.prototype.trackException = function (exception, handledAt, properties, measurements, severityLevel) {
        return this.appInsights && this.appInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
    };
    AppInsights.prototype.setAuthenticatedUserContext = function (authenticatedUserId, accountId) {
        return this.appInsights && this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId);
    };
    AppInsights.prototype.clearAuthenticatedUserContext = function () {
        return this.appInsights && this.appInsights.clearAuthenticatedUserContext();
    };
    AppInsights = __decorate([
        injectable(),
        __metadata("design:paramtypes", [])
    ], AppInsights);
    return AppInsights;
}());
export { AppInsights };
