import { __awaiter, __generator } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { StorageService } from 'core/services/storage';
import { UserSessionService } from 'common/services';
import { AppInsights } from 'common/app-insights';
import { addSessionEventHandlers } from './session-event-handlers';
export function registerCoreDependencies(container) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            container.bind(AppInsights).toSelf().inSingletonScope();
            container.bind(RestClient).toSelf().inSingletonScope();
            container.bind(UserSessionService).toSelf().inSingletonScope().onActivation(function (ctx, sessionService) {
                addSessionEventHandlers(sessionService, ctx.container.get(AppInsights));
                return sessionService;
            });
            container.bind(StorageService).toConstantValue(new StorageService(window.sessionStorage));
            return [2 /*return*/, container];
        });
    });
}
;
