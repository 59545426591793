import { __awaiter, __generator } from "tslib";
import 'reflect-metadata';
import bootstrap from 'common/bootstrap';
import { createAppInsightsErrorHandler } from 'common/utils/error-handlers';
import { registerAppDependencies } from './config/dependencies';
import { Routes } from './config/routes';
import { attachPageViewTracking } from 'common/utils/analytics';
bootstrap({ name: 'ocp.app' }).then(function (env) { return __awaiter(void 0, void 0, void 0, function () {
    var Vue, Config, VueInversify, VueClassComponent, Container, AppInsights, _a, VueRouter, Layout, router;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                Vue = env.Vue, Config = env.Config, VueInversify = env.VueInversify, VueClassComponent = env.VueClassComponent, Container = env.Container, AppInsights = env.AppInsights;
                return [4 /*yield*/, Promise.all([
                        import('vue-router'),
                        import('./themes/bilya/layout.vue')
                    ])];
            case 1:
                _a = _b.sent(), VueRouter = _a[0].default, Layout = _a[1].default;
                VueClassComponent.registerHooks([
                    'beforeRouteEnter',
                    'beforeRouteLeave',
                ]);
                Vue.use(VueRouter);
                Vue.use(VueInversify);
                return [4 /*yield*/, registerAppDependencies(Container, Config)];
            case 2:
                _b.sent();
                router = new VueRouter({
                    mode: 'history',
                    routes: Routes,
                    scrollBehavior: function (to, from, saved) {
                        return saved || { x: 0, y: 0 };
                    }
                });
                attachPageViewTracking(router, AppInsights);
                return [2 /*return*/, new Vue({
                        el: '#app',
                        container: Container,
                        errorCaptured: createAppInsightsErrorHandler(AppInsights, Config),
                        render: function (r) { return r(Layout); },
                        router: router
                    })];
        }
    });
}); });
