import { __decorate, __extends, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { injectable } from 'core/decorators';
import { ApiService } from './api';
var TemplateService = /** @class */ (function (_super) {
    __extends(TemplateService, _super);
    function TemplateService(client) {
        return _super.call(this, client) || this;
    }
    TemplateService.prototype.getTemplates = function () {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get('/templates'); });
    };
    TemplateService.prototype.getTemplate = function (id) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/templates/".concat(encodeURIComponent(id.toString()))); });
    };
    TemplateService.prototype.getMailboxTemplates = function (mailboxId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/mailboxes/".concat(encodeURIComponent(mailboxId.toString()), "/templates")); });
    };
    TemplateService.prototype.createTemplate = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post('/templates', model); });
    };
    TemplateService.prototype.updateTemplate = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/templates/".concat(encodeURIComponent(model.id)), model); });
    };
    TemplateService.prototype.deleteTemplate = function (id) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.delete("/templates/".concat(encodeURIComponent(id.toString()))); });
    };
    TemplateService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], TemplateService);
    return TemplateService;
}(ApiService));
export { TemplateService };
