import { __awaiter, __generator } from "tslib";
import { registerGlobalComponents } from 'common/config';
import { AppInsights } from 'common/app-insights';
import polyfill from 'core/polyfill';
import createAppContainer from 'common/container';
;
var singleton = null;
export default function bootstrap(config) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, vueModule, vueInversifyModule, VueComponentModule, container, Vue;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        import('vue'),
                        import('vue-inversify'),
                        import('vue-class-component'),
                        createAppContainer(),
                        polyfill()
                    ])];
                case 1:
                    _a = _b.sent(), vueModule = _a[0], vueInversifyModule = _a[1], VueComponentModule = _a[2], container = _a[3];
                    Vue = vueModule.default;
                    if (!(singleton == null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, registerGlobalComponents(Vue)];
                case 2:
                    _b.sent();
                    singleton = {};
                    _b.label = 3;
                case 3: return [2 /*return*/, {
                        Config: config,
                        Vue: Vue,
                        VueInversify: vueInversifyModule.default,
                        VueClassComponent: VueComponentModule.default,
                        Container: container,
                        AppInsights: container.get(AppInsights)
                    }];
            }
        });
    });
}
