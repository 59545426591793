import { __awaiter, __decorate, __generator, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { FriendlyError, RestError } from 'common/errors';
import { injectable } from 'core/decorators';
var ApiService = /** @class */ (function () {
    function ApiService(client) {
        this.client = client;
    }
    ApiService.prototype.sendRequest = function (fn) {
        return __awaiter(this, void 0, void 0, function () {
            var res, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fn()];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        throw new FriendlyError('An unexpected error occurred when contacting the server.', ex_1 instanceof Error ? ex_1 : undefined);
                    case 3:
                        if (res.status < 200 || res.status >= 400)
                            throw new RestError(res);
                        return [2 /*return*/, res.data];
                }
            });
        });
    };
    ApiService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], ApiService);
    return ApiService;
}());
export { ApiService };
