import { __spreadArray } from "tslib";
export function registerGlobalComponents(vue) {
    var imports = [
        ['navLink', import('common/components/nav-link.vue')],
        ['icon', import('common/components/icon.vue')],
        ['panel', import('common/components/panel.vue')],
        ['pageContent', import('common/components/page-content.vue')],
        ['date', import('common/components/date.vue')],
        ['loadingSpinner', import('common/components/loading-spinner.vue')]
    ];
    vue.directive('bubble', function (_, binding, vnode) {
        Object.keys(binding.modifiers).forEach(function (event) {
            // Bubble events of Vue components
            if (vnode.componentInstance) {
                vnode.componentInstance.$on(event, function () {
                    var _a;
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    vnode.context && (_a = vnode.context).$emit.apply(_a, __spreadArray([event], args, false));
                });
            }
        });
    });
    return Promise.all(imports.map(function (i) { return i[1]; })).then(function (modules) {
        for (var i = 0, j = modules.length; i < j; ++i)
            vue.component(imports[i][0], modules[i].default);
    });
}
