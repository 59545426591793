import * as es6Promise from 'es6-promise';
es6Promise.polyfill();
if (typeof (Promise.prototype.finally) !== 'function')
    Promise.prototype.finally = es6Promise.Promise.prototype.finally;
export default function polyfill() {
    var promises = [];
    if (typeof (Array.prototype.find) !== 'function') {
        promises.push(import('core-js/features/array/find'));
        promises.push(import('core-js/features/array/find-index'));
    }
    if (typeof (Array.from) !== 'function') {
        promises.push(import('core-js/features/array/from'));
    }
    if (typeof (Object.assign) !== 'function')
        promises.push(import('core-js/features/object/assign'));
    if (typeof (fetch) !== 'function')
        promises.push(import('whatwg-fetch'));
    // NOTE: Try not to polyfill something if there are somewhat viable alternatives.
    return Promise.all(promises);
}
