import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { injectable } from 'core/decorators';
import { ApiService } from './api';
import { RestError } from 'common/errors';
var PersonService = /** @class */ (function (_super) {
    __extends(PersonService, _super);
    function PersonService(client) {
        return _super.call(this, client) || this;
    }
    PersonService.prototype.getPersonById = function (id, type) {
        return __awaiter(this, void 0, void 0, function () {
            var url, ex_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "/people/".concat(encodeURIComponent(id)) + (type != null ? "?type=".concat(encodeURIComponent(type)) : '');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.sendRequest(function () { return _this.client.get(url); })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        ex_1 = _a.sent();
                        if (RestError.isRestError(ex_1) && ex_1.statusCode == 404)
                            return [2 /*return*/, null];
                        throw ex_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PersonService.prototype.getExemptRecipients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                url = '/people/exempt';
                return [2 /*return*/, this.sendRequest(function () { return _this.client.get(url); })];
            });
        });
    };
    PersonService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], PersonService);
    return PersonService;
}(ApiService));
export { PersonService };
