import { __awaiter, __decorate, __generator } from "tslib";
import { injectable } from 'core/decorators';
export var jsonContent = function (data) {
    return {
        contentType: 'application/json; charset=utf-8',
        body: JSON.stringify(data)
    };
};
export var urlEncodedFormContent = function (data) {
    return {
        contentType: 'application/x-www-form-urlencoded',
        body: createQueryString(data)
    };
};
var HttpClient = /** @class */ (function () {
    function HttpClient() {
        var _this = this;
        this.middleware = [];
        this.pipeline = function (req) { return _this.sendRequest(req); };
    }
    HttpClient.prototype.use = function (middleware) {
        var _this = this;
        this.middleware.push(middleware);
        // Reconstruct the pipeline
        var pipeline = function (req) { return _this.sendRequest(req); };
        var _loop_1 = function (i) {
            var next = pipeline;
            var middleware_1 = this_1.middleware[i - 1];
            pipeline = function (req) { return middleware_1(req, next); };
        };
        var this_1 = this;
        for (var i = this.middleware.length; i > 0; --i) {
            _loop_1(i);
        }
        this.pipeline = pipeline;
        return this;
    };
    HttpClient.prototype.send = function (req) {
        return this.pipeline(req);
    };
    HttpClient.prototype.get = function (url, params) {
        return this.pipeline(createRequest('GET', url, params));
    };
    HttpClient.prototype.post = function (url, content, params) {
        return this.pipeline(createRequest('POST', url, params, content));
    };
    HttpClient.prototype.delete = function (url, params) {
        return this.pipeline(createRequest('DELETE', url, params));
    };
    HttpClient.prototype.patch = function (url, content, params) {
        return this.pipeline(createRequest('PATCH', url, params, content));
    };
    HttpClient.prototype.put = function (url, content, params) {
        return this.pipeline(createRequest('PUT', url, params, content));
    };
    HttpClient.prototype.sendRequest = function (req) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, req.onUploadProgress || req.onDownloadProgress ? this.sendXhrRequest(req) : this.sendFetchRequest(req)];
            });
        });
    };
    HttpClient.prototype.sendXhrRequest = function (req) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var xhr = new XMLHttpRequest();
                        if (req.onUploadProgress)
                            xhr.upload.onprogress = req.onUploadProgress;
                        if (req.onDownloadProgress)
                            xhr.onprogress = req.onDownloadProgress;
                        xhr.onload = function () {
                            resolve({
                                request: req,
                                status: xhr.status,
                                headers: parseXHRHeaders(xhr.getAllResponseHeaders()),
                                url: xhr.responseURL,
                                body: xhr.responseText
                            });
                        };
                        xhr.onerror = function () { return reject(xhr); };
                        xhr.open(req.method, req.url);
                        xhr.withCredentials = req.credentials !== undefined && req.credentials != 'omit';
                        if (req.headers)
                            Object.keys(req.headers).forEach(function (k) { return xhr.setRequestHeader(k, req.headers[k]); });
                        xhr.send(req.body);
                    })];
            });
        });
    };
    HttpClient.prototype.sendFetchRequest = function (req) {
        return __awaiter(this, void 0, void 0, function () {
            var res, headers;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetch(req.url, req)];
                    case 1:
                        res = _b.sent();
                        headers = {};
                        res.headers.forEach(function (value, name) { return headers[name.toLowerCase()] = value; });
                        _a = {
                            request: req,
                            status: res.status,
                            headers: headers,
                            url: res.url
                        };
                        return [4 /*yield*/, res.text()];
                    case 2: return [2 /*return*/, (_a.body = _b.sent(),
                            _a)];
                }
            });
        });
    };
    HttpClient = __decorate([
        injectable()
    ], HttpClient);
    return HttpClient;
}());
export { HttpClient };
function createRequest(method, url, params, content) {
    if (typeof (params) !== 'undefined')
        url += (url.indexOf('?') >= 0 ? '&' : '?') + createQueryString(params);
    return {
        method: method,
        url: url,
        headers: content && content.contentType ? { 'Content-Type': content.contentType } : undefined,
        body: content ? content.body : undefined
    };
}
function createQueryString(params) {
    return Object.keys(params)
        .filter(function (k) { return typeof (params[k]) !== 'undefined'; })
        .map(function (k) { return "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(params[k].toString())); })
        .join('&');
}
// Based on implementation in https://github.com/nervgh/angular-file-upload/blob/master/src/services/FileUploader.js
function parseXHRHeaders(headers) {
    var parsed = {};
    if (!headers)
        return parsed;
    headers.split('\n').forEach(function (line) {
        var i = line.indexOf(':');
        var key = line.slice(0, i).trim().toLowerCase();
        var val = line.slice(i + 1).trim();
        if (key) {
            parsed[key] = parsed[key] ? parsed[key] + ', ' + val : val;
        }
    });
    return parsed;
}
