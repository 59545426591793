import { __decorate, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { jsonContent, HttpClient } from './http-client';
var RestClient = /** @class */ (function () {
    function RestClient(httpClient) {
        this.httpClient = httpClient;
    }
    RestClient.prototype.get = function (url, params) {
        return this.httpClient.get(url, params).then(handleResponse);
    };
    RestClient.prototype.delete = function (url, params) {
        return this.httpClient.delete(url, params).then(handleResponse);
    };
    RestClient.prototype.post = function (url, data, params) {
        return this.httpClient.post(url, jsonContent(data), params).then(handleResponse);
    };
    RestClient.prototype.patch = function (url, data, params) {
        return this.httpClient.patch(url, jsonContent(data), params).then(handleResponse);
    };
    RestClient.prototype.put = function (url, data, params) {
        return this.httpClient.put(url, jsonContent(data), params).then(handleResponse);
    };
    RestClient.prototype.send = function (req) {
        return this.httpClient.send(req).then(handleResponse);
    };
    RestClient = __decorate([
        injectable(),
        __metadata("design:paramtypes", [HttpClient])
    ], RestClient);
    return RestClient;
}());
export { RestClient };
function handleResponse(res) {
    var contentType = res.headers['content-type'];
    if (typeof (contentType) !== 'string' || contentType.indexOf('application/json') !== 0)
        throw new Error("Invalid \"Content-Type\" header returned from server; expected \"application/json\", got \"".concat(contentType || 'undefined', "\""));
    var body = JSON.parse(res.body);
    if (body.status < 500)
        return body;
    // Unhandled structured response from the server
    var error = new Error("".concat(res.request.method, " \"").concat(res.request.url, "\" ").concat(body.status, " (").concat(body.errors.join('; '), ")"));
    error.name = 'RestClientUnhandledResponseError';
    error.response = body;
    return Promise.reject(error);
}
