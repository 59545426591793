import { __extends } from "tslib";
var FriendlyError = /** @class */ (function (_super) {
    __extends(FriendlyError, _super);
    function FriendlyError(messages, innerError) {
        var _this = _super.call(this, Array.isArray(messages) ? messages[0] : messages) || this;
        _this.IsFriendlyError = true;
        _this.name = FriendlyError.Name;
        _this.messages = Array.isArray(messages) ? messages : [messages];
        _this.innerError = innerError;
        return _this;
    }
    FriendlyError.isFriendlyError = function (error) {
        return !!error.IsFriendlyError;
    };
    FriendlyError.Name = 'FriendlyError';
    return FriendlyError;
}(Error));
export { FriendlyError };
