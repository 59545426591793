var StorageKey = 'app-storage';
var StorageService = /** @class */ (function () {
    function StorageService(backingStorage) {
        this.storage = backingStorage;
        this.canPersist = isStorageSupported(backingStorage);
        this.state = {};
        this.reload();
    }
    StorageService.prototype.set = function (key, value) {
        this.state[key] = value;
        this.save();
        return this;
    };
    StorageService.prototype.get = function (key, defaultValue) {
        return this.has(key) ? this.state[key] : defaultValue;
    };
    StorageService.prototype.unset = function (key) {
        var oldValue;
        if (this.has(key)) {
            oldValue = this.state[key];
            delete this.state[key];
            this.save();
        }
        return oldValue;
    };
    StorageService.prototype.clear = function () {
        this.state = {};
        this.save();
        return this;
    };
    StorageService.prototype.has = function (key) {
        return this.state.hasOwnProperty(key);
    };
    StorageService.prototype.save = function () {
        if (this.canPersist) {
            this.storage.setItem(StorageKey, JSON.stringify(this.state));
        }
    };
    StorageService.prototype.reload = function () {
        var state = {};
        if (this.canPersist) {
            var json = this.storage.getItem(StorageKey);
            var deserialised = json && JSON.parse(json);
            if (deserialised && typeof (deserialised) === 'object')
                state = deserialised;
        }
        this.state = state;
    };
    return StorageService;
}());
export { StorageService };
function isStorageSupported(storage) {
    try {
        // This additional test is due to Private Mode in  Safari in 
        // iOS 10 *technically* having sessionStorage, but having a quota
        // of 0, making any `setItem` call fail.
        var testKey = '__storage_test';
        storage.setItem(testKey, testKey);
        storage.removeItem(testKey);
        return true;
    }
    catch (ex) {
        return false;
    }
}
