import { __awaiter, __generator } from "tslib";
import { registerCoreDependencies } from './config/core-dependencies';
var CoreContainer = null;
export default function createAppContainer() {
    return __awaiter(this, void 0, void 0, function () {
        var inversify;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(CoreContainer == null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, import('inversify')];
                case 1:
                    inversify = _a.sent();
                    CoreContainer = new inversify.Container();
                    return [4 /*yield*/, registerCoreDependencies(CoreContainer)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/, CoreContainer.createChild()];
            }
        });
    });
}
