import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService } from './api';
var OfficialComunicationService = /** @class */ (function (_super) {
    __extends(OfficialComunicationService, _super);
    function OfficialComunicationService(client) {
        return _super.call(this, client) || this;
    }
    OfficialComunicationService.prototype.getOfficialCommunicationSummariesByUser = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.sendRequest(function () { return _this.client.get("/users/".concat(encodeURIComponent(userId), "/official-communications")); })];
            });
        });
    };
    OfficialComunicationService.prototype.getOfficialCommunication = function (userId, messageId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.sendRequest(function () { return _this.client.get("/users/".concat(encodeURIComponent(userId), "/official-communications/").concat(encodeURIComponent(messageId.toString()))); })];
            });
        });
    };
    OfficialComunicationService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], OfficialComunicationService);
    return OfficialComunicationService;
}(ApiService));
export { OfficialComunicationService };
