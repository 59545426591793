export var StateKey = 'oidc-state';
export var IdTokenKey = 'oidc-idtoken';
export var ReturnToKey = 'returnTo';
export var LoginRoute = 'auth.login';
export var LogoutRoute = 'auth.logout';
export function createAuthRoutes(basePath) {
    return [
        { name: LoginRoute, path: "".concat(basePath, "/oidc-login"), component: function () { return import('../views/login.vue'); } },
        { name: LogoutRoute, path: "".concat(basePath, "/oidc-logout"), component: function () { return import('../views/logout.vue'); } }
    ];
}
;
