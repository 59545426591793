import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService } from './api';
var FileUploadService = /** @class */ (function (_super) {
    __extends(FileUploadService, _super);
    function FileUploadService(client) {
        return _super.call(this, client) || this;
    }
    FileUploadService.prototype.uploadFile = function (file, fileName, onUploadProgress, onDownloadProgress) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            var _this = this;
            return __generator(this, function (_a) {
                formData = new FormData();
                formData.append('file', file, fileName);
                return [2 /*return*/, this.sendRequest(function () { return _this.client.send({
                        method: 'POST',
                        url: '/files',
                        body: formData,
                        onDownloadProgress: onDownloadProgress,
                        onUploadProgress: onUploadProgress
                    }); })];
            });
        });
    };
    FileUploadService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], FileUploadService);
    return FileUploadService;
}(ApiService));
export { FileUploadService };
