import { __extends } from "tslib";
import { FriendlyError } from './friendly';
var RestError = /** @class */ (function (_super) {
    __extends(RestError, _super);
    function RestError(responseOrMessage, statusCode, data) {
        var _this = this;
        var usingValueParams = typeof responseOrMessage === 'string' || Array.isArray(responseOrMessage);
        var response = !usingValueParams
            ? responseOrMessage
            : {
                errors: Array.isArray(responseOrMessage) ? responseOrMessage : [responseOrMessage],
                status: statusCode ? statusCode : 0,
                data: data
            };
        _this = _super.call(this, response.errors) || this;
        _this.name = RestError.Name;
        _this.response = response;
        _this.statusCode = response.status;
        return _this;
    }
    RestError.isRestError = function (error) {
        return this.isFriendlyError(error) && error.name === RestError.Name;
    };
    RestError.Name = 'RestError';
    return RestError;
}(FriendlyError));
export { RestError };
