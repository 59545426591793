export function dedupeCallsTo(fn) {
    var pending = null;
    var clearPending = function () { return pending = null; };
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!pending) {
            pending = fn.apply(this, args);
            pending.finally(clearPending);
        }
        return pending;
    };
}
