;
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
    UserRole["StudentLookup"] = "studentlookup";
    UserRole["Staff"] = "staff";
})(UserRole || (UserRole = {}));
;
export function isInRole(user) {
    var roles = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        roles[_i - 1] = arguments[_i];
    }
    return user.roles.some(function (userRole) { return roles.some(function (role) { return userRole === role; }); });
}
