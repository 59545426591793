import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { StorageService } from './storage';
import { RestClient } from './rest-client';
import { EventEmitter } from 'core/event-emitter';
import { dedupeCallsTo } from 'core/utils';
var StorageKey = 'officialcomms.user';
var SessionService = /** @class */ (function (_super) {
    __extends(SessionService, _super);
    function SessionService(restClient, storageService) {
        var _this = _super.call(this) || this;
        _this.restClient = restClient;
        _this.storageService = storageService;
        _this.fetch = dedupeCallsTo(_this.fetch);
        return _this;
    }
    SessionService.prototype.current = function () {
        return this.storageService.get(StorageKey) || null;
    };
    SessionService.prototype.fetch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restClient.get('/sessions/current')];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.setUser(res.data)];
                }
            });
        });
    };
    SessionService.prototype.clear = function () {
        var current = this.current();
        if (current != null)
            this.emit('session:end', current);
        this.storageService.clear();
    };
    SessionService.prototype.login = function (credentials) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restClient.post('/sessions', credentials)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, this.setUser(res.data)];
                }
            });
        });
    };
    SessionService.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.clear();
                        return [4 /*yield*/, this.restClient.delete("/sessions")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionService.prototype.refresh = function () {
        return Promise.resolve(null);
    };
    SessionService.prototype.setUser = function (user) {
        if (user) {
            this.storageService.set(StorageKey, Object.assign({}, user));
            this.emit('session:start', user);
        }
        return user || null;
    };
    SessionService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient, StorageService])
    ], SessionService);
    return SessionService;
}(EventEmitter));
export { SessionService };
