import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { injectable } from 'core/decorators';
import { ApiService } from './api';
import { RestError } from 'common/errors';
var MessageService = /** @class */ (function (_super) {
    __extends(MessageService, _super);
    function MessageService(client) {
        return _super.call(this, client) || this;
    }
    MessageService.prototype.getMessage = function (messageId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/messages/".concat(encodeURIComponent(messageId))); });
    };
    MessageService.prototype.createMessage = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post('/messages', model); });
    };
    MessageService.prototype.updateMessage = function (messageId, model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/messages/".concat(encodeURIComponent(messageId)), model); });
    };
    MessageService.prototype.getMessageRecipientSummary = function (messageId, recipientListLength) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/messages/".concat(encodeURIComponent(messageId), "/recipients/summary"), typeof recipientListLength === 'undefined' ? undefined : { recipientListLength: recipientListLength }); });
    };
    MessageService.prototype.getMessageRecipient = function (messageId, recipientId) {
        return __awaiter(this, void 0, void 0, function () {
            var ex_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.sendRequest(function () { return _this.client.get("/messages/".concat(encodeURIComponent(messageId), "/recipients/").concat(encodeURIComponent(recipientId))); })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_1 = _a.sent();
                        if (RestError.isRestError(ex_1) && ex_1.statusCode == 404)
                            return [2 /*return*/, null];
                        throw ex_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MessageService.prototype.getMessageRecipientTemplateModels = function (messageId, recipientId) {
        return __awaiter(this, void 0, void 0, function () {
            var ex_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.sendRequest(function () { return _this.client.get("/messages/".concat(encodeURIComponent(messageId), "/recipient-template-models/").concat(encodeURIComponent(recipientId))); })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        ex_2 = _a.sent();
                        if (RestError.isRestError(ex_2) && ex_2.statusCode == 404)
                            return [2 /*return*/, null];
                        throw ex_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MessageService.prototype.updateMessageRecipients = function (messageId, recipientIds) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/messages/".concat(encodeURIComponent(messageId), "/recipients"), { recipientIds: recipientIds }); });
    };
    MessageService.prototype.updateMessageRecipientTemplateModels = function (messageId, templateModels) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/messages/".concat(encodeURIComponent(messageId), "/recipient-template-models"), { templateModels: templateModels }); });
    };
    MessageService.prototype.sendMessage = function (messageId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/messages/".concat(encodeURIComponent(messageId), "/sent"), { messageId: messageId }); });
    };
    MessageService.prototype.getMessageOverview = function (messageId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/sent-messages/".concat(encodeURIComponent(messageId))); });
    };
    MessageService.prototype.emailMessage = function (messageId, userId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/sent-messages/".concat(messageId, "/email/").concat(encodeURIComponent(userId))); });
    };
    MessageService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], MessageService);
    return MessageService;
}(ApiService));
export { MessageService };
