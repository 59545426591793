import { __decorate, __extends, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { injectable } from 'core/decorators';
import { ApiService } from './api';
var MailboxService = /** @class */ (function (_super) {
    __extends(MailboxService, _super);
    function MailboxService(client) {
        return _super.call(this, client) || this;
    }
    MailboxService.prototype.getMailboxes = function () {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get('/mailboxes'); });
    };
    MailboxService.prototype.getArchivedMailboxes = function () {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get('/mailboxes/archived'); });
    };
    MailboxService.prototype.getUserMailboxes = function (userId, claims) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/users/".concat(encodeURIComponent(userId), "/mailboxes/"), { claims: claims }); });
    };
    MailboxService.prototype.getArchivedUserMailboxes = function (userId, claims) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/users/".concat(encodeURIComponent(userId), "/mailboxes/archived"), { claims: claims }); });
    };
    MailboxService.prototype.getUserMailbox = function (userId, mailboxId, claims) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/users/".concat(encodeURIComponent(userId), "/mailboxes/").concat(encodeURIComponent(mailboxId)), { claims: claims }); });
    };
    MailboxService.prototype.getMailbox = function (id) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/mailboxes/".concat(encodeURIComponent(id.toString()))); });
    };
    MailboxService.prototype.getMessages = function (id) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/mailboxes/".concat(encodeURIComponent(id.toString()), "/sent-message-summaries")); });
    };
    MailboxService.prototype.createMailbox = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post('/mailboxes', model); });
    };
    MailboxService.prototype.updateMailboxOwner = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/mailboxes/".concat(encodeURIComponent(model.id), "/owner"), model); });
    };
    MailboxService.prototype.updateMailboxSensitivity = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/mailboxes/".concat(encodeURIComponent(model.id), "/sensitivity"), model); });
    };
    MailboxService.prototype.updateMailboxName = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/mailboxes/".concat(encodeURIComponent(model.id), "/name"), model); });
    };
    MailboxService.prototype.updateMailboxDisplayName = function (model) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/mailboxes/".concat(encodeURIComponent(model.id), "/display-name"), model); });
    };
    MailboxService.prototype.deleteMailboxUser = function (mailboxId, userId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.delete("/mailboxes/".concat(encodeURIComponent(mailboxId), "/users/").concat(encodeURIComponent(userId))); });
    };
    MailboxService.prototype.addMailboxUserClaims = function (mailboxId, userId, claims) {
        var _this = this;
        var url = "/mailboxes/".concat(encodeURIComponent(mailboxId.toString()), "/users/").concat(encodeURIComponent(userId), "/claims");
        return this.sendRequest(function () { return _this.client.post(url, { claims: claims }); });
    };
    MailboxService.prototype.updateUserMailboxes = function (userId, mailboxes) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/users/".concat(encodeURIComponent(userId), "/mailboxes"), mailboxes); });
    };
    MailboxService.prototype.archiveMailbox = function (mailbox) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/mailboxes/".concat(encodeURIComponent(mailbox.id), "/archived"), mailbox); });
    };
    MailboxService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], MailboxService);
    return MailboxService;
}(ApiService));
export { MailboxService };
