import { __awaiter, __generator } from "tslib";
import { HttpClient } from 'core/services/http-client';
import { addWebApiMiddleware } from 'common/config/http-middleware';
import { MailboxService, PersonService, MessageService, FileUploadService, OfficialComunicationService, TemplateService } from 'app/services';
export function registerAppDependencies(container, config) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            container.bind(HttpClient).toSelf().inSingletonScope().onActivation(function (_, client) {
                addWebApiMiddleware(client, config);
                return client;
            });
            container.bind(MailboxService).toSelf()
                .inSingletonScope();
            container.bind(PersonService).toSelf()
                .inSingletonScope();
            container.bind(MessageService).toSelf()
                .inSingletonScope();
            container.bind(FileUploadService).toSelf()
                .inSingletonScope();
            container.bind(OfficialComunicationService).toSelf()
                .inSingletonScope();
            container.bind(TemplateService).toSelf()
                .inSingletonScope();
            return [2 /*return*/, container];
        });
    });
}
