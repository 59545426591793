import { __spreadArray } from "tslib";
import { createAuthRoutes } from 'auth/config';
import { UserRole } from 'common/models';
var AbstractRoute = {
    render: function (createElement) { return createElement('router-view'); }
};
export var Routes = __spreadArray(__spreadArray([], createAuthRoutes('/auth'), true), [
    { name: 'dashboard', path: '/', component: function () { return import('../views/dashboard.vue'); }, meta: { title: 'Dashboard' } },
    { name: 'create-mailbox', path: '/mailbox/create', component: function () { return import('../views/create-mailbox.vue'); } },
    {
        path: '/mailbox/:mailboxId(\\d+)',
        component: AbstractRoute,
        children: [
            { name: 'edit-mailbox', path: 'edit', component: function () { return import('../views/edit-mailbox.vue'); }, props: propMap({ mailboxId: Number }) },
            { name: 'mailbox-users', path: 'users', component: function () { return import('../views/mailbox-users.vue'); }, props: propMap({ mailboxId: Number }) },
        ]
    },
    {
        name: 'mailbox-sent-messages', path: '/mailbox/:mailboxId(\\d+)/messages',
        component: function () { return import('../views/mailbox-sent-messages.vue'); },
        props: propMap({ mailboxId: Number })
    },
    {
        name: 'read-mailbox-message', path: '/mailbox/:mailboxId(\\d+)/messages/:messageId(\\d+)',
        component: function () { return import('../views/read-mailbox-message.vue'); },
        props: propMap({ mailboxId: Number, messageId: Number, showSuccessMessage: Boolean })
    },
    {
        name: 'send-official-communications',
        path: '/send/:messageId(\\d+)?/:step?',
        component: function () { return import('../views/send-message.vue'); },
        meta: { title: 'Send Official Communications' },
        props: propMap({ messageId: OptionalNumber, step: true })
    },
    {
        name: 'student-lookup', path: '/student-lookup/:userId?',
        component: function () { return import('../views/student-lookup.vue'); },
        props: propMap({ userId: OptionalString })
    },
    {
        name: 'read-official-communication', path: '/official-communication/:userId/messages/:messageId(\\d+)',
        component: function () { return import('../views/read-official-communication.vue'); },
        props: propMap({ userId: String, messageId: Number })
    },
    {
        name: 'staff-lookup', path: '/staff-lookup/:userId?',
        component: function () { return import('../views/staff-lookup.vue'); },
        props: propMap({ userId: OptionalString })
    },
    {
        name: 'view-templates', path: '/templates',
        component: function () { return import('../views/view-templates.vue'); },
        meta: { title: 'Templates', roles: [UserRole.Admin] },
        props: propMap({ showSuccessMessage: Boolean })
    },
    {
        name: 'create-template', path: '/templates/create',
        component: function () { return import('../views/edit-template.vue'); }
    },
    {
        name: 'edit-template', path: '/templates/:templateId(\\d+)/edit',
        component: function () { return import('../views/edit-template.vue'); },
        props: propMap({ templateId: Number })
    },
    { name: 'archived-mailboxes', path: '/mailboxes/archived', component: function () { return import('../views/archived-mailboxes.vue'); } },
    { name: 'not-found', path: '*', component: function () { return import('../views/not-found.vue'); } },
], false);
function OptionalNumber(value) {
    return value == null ? null : Number(value);
}
function OptionalString(value) {
    return value == null ? null : String(value);
}
function propMap(propMappers) {
    return function (route) {
        var mappedProps = {};
        for (var propName in propMappers) {
            var mapper = propMappers[propName];
            var propValue = route.params[propName];
            mappedProps[propName] = typeof (mapper) === 'function' ? mapper(propValue) : propValue;
        }
        return mappedProps;
    };
}
